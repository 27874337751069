import {Dispatch} from 'redux'
import {STEPPER_ACTIONS} from './ACTION_TYPES'
import {dispatch} from 'jest-circus/build/state'


export const setNext = (data = {}) => (dispatch: Dispatch) => {
    dispatch({
        type: STEPPER_ACTIONS.SET_NEXT,
        payload: data
    })
}

export const setPrev = (data = {}) => (dispatch: Dispatch) => {
    dispatch({
        type: STEPPER_ACTIONS.SET_PREV,
        payload: data
    })
}

export const setSteps = (steps: string[]) => (dispatch: Dispatch) => {
    dispatch({
        type: STEPPER_ACTIONS.SET_STEPS,
        payload: steps,
    })
}

export const setData = (data: any) => (dispatch: Dispatch) => {
   
    dispatch(
        {
            type: STEPPER_ACTIONS.SET_DATA,
            payload: data
        }
    )
}
