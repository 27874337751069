import React from 'react'
import {Typography, Container, Stepper, Step, Paper, Alert, AlertTitle} from '@mui/material'
import OrderStepper from './components/OrderStepper'
import './Order.scss'


export default function Order({wichtel = false}: { wichtel?: boolean }) {
    return (
        <div className={'order'}>
            <Container className={'orderHeader'}>
                <Typography variant={'h2'}>
                    Bestellen
                </Typography>
            </Container>
            <Container className={'orderForm'}>
                <OrderStepper wichtel={wichtel}/>
            </Container>
        </div>
    )
}
