import React, {useContext, useState} from 'react'
import {Button, Container, IconButton} from '@mui/material'
import {Info} from '@mui/icons-material'
import ShoppingCartButton from '../../shared/ShoppingCartButton'
import ProductPopUp from './ProductPopUp'
import {ProductContext} from '../Product'


export default function ProductActions({}) {

    const [open, setOpen] = useState(false)
    const {id} = useContext(ProductContext)

    const handleClick = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }

    const handlePopup = () => {
        if (open) {
            return <ProductPopUp productId={id} open={open} onClose={handleClose}/>
        } else {
            return ''
        }
    }


    return (
        <Container className={'productActions'}>
            <Button startIcon={<Info/>} variant={'text'} onClick={handleClick}>
                Info
            </Button>
            <ShoppingCartButton/>
            {handlePopup()}
        </Container>
    )
}