import * as React from 'react'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepButton from '@mui/material/StepButton'
import Button from '@mui/material/Button'
import ShoppingCartStep from '../ShoppingCartStep/ShoppingCartStep'
import OrderStep from '../OrderStep/OrderStep'
import WichtelStep from '../WichtelStep/WichtelStep'
import {Paper} from '@mui/material'
import {useDispatch, useSelector} from 'react-redux'
import {setSteps} from '../../../actions/stepperActions'
import {StepperType} from '../../../reducer/StepperReducer'
import SuccessStep from '../SuccessStep/SuccessStep'


export const OrderContext = React.createContext({
    handleNext: () => {
    },
    handlePrev: () => {
    },
    lastStep: false,
    activeStep: 0,
})


export default function OrderStepper({wichtel = false}: { wichtel?: boolean }) {
    const {stepper}: any = useSelector(state => state)
    const {activeStep, steps, data} = stepper


    if (wichtel && !steps.includes('Empfänger')) {
        steps.splice(1, 0, 'Empfänger')
    }

    const getStep = () => {
        return stepComponents[activeStep]
    }

    const totalSteps = () => {
        return steps.length
    }


    const stepComponents = [<ShoppingCartStep/>, <OrderStep/>, <SuccessStep data={data}/>]
    if (wichtel) {
        stepComponents.splice(1, 0, <WichtelStep/>)
    }



    return (
        <Paper sx={{width: '100%'}} className={'orderStepper'}>
            <Stepper activeStep={activeStep} className={'stepBar'}>
                {steps.map((label: string) => (
                    <Step key={label}>
                        <StepButton color="inherit">
                            {label}
                        </StepButton>
                    </Step>
                ))}
            </Stepper>
            <div className={'stepWrapper'}>
                <div className="stepContent">
                    {getStep()}
                </div>
            </div>
        </Paper>
    )

}
