import React, {useState} from 'react'
import {TextField, Grid, FormControlLabel, Checkbox, Button, Typography} from '@mui/material'
import SteperActions from './components/StepperActions'
import {useDispatch, useSelector} from 'react-redux'
import {setNext} from '../../../actions/stepperActions'
import {shopItem} from '../../../reducer/ShopReducer'
import {sendOrder} from '../../../actions/shopActions'
import {async} from 'q'
import {API, orderProducts} from '../../../Api'

export interface State {
    firstname: string,
    lastname: string,
    streetName: string,
    postalCode: string,
    city: string,
    email: string,
    phoneNumber?: string,
    notice?: string,
    checkBox: boolean,
}

const allErrors: Error<State> = {
    firstname: true,
    lastname: true,
    streetName: true,
    postalCode: true,
    city: true,
    email: true,
    checkBox: true
}

type Error<Type> = {
    [Property in keyof Type]?: boolean
}

const NAME_REGEX: RegExp = /^([A-Za-z\- —]){2,100}/
const POSTAL_CODE_REGEX: RegExp = /^[0-9]{5}/
const EMAIL_REGEX: RegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/


export default function OrderStep() {

    const dispatch = useDispatch()

    const [values, setValues] = useState<State>({
        firstname: '', lastname: '', streetName: '', postalCode: '', city: '', email: '', notice: '', checkBox: false,
    })
    const [errors, setErrors] = useState<Error<State>>({})

    const handleChange = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({
            ...values,
            [prop]: event.target.value
        })
    }
   
    const {shop, stepper}: any = useSelector(state => state)
    

    const handleCheckbox = () => {
        setValues({...values, checkBox: !values.checkBox})
    }

    const validateInput = (prop: keyof State | string): boolean | undefined => {
        switch (prop) {
            case 'firstname':
            case 'lastname':
            case 'city':
            case 'streetName':
                return !NAME_REGEX.test(values[prop])
            case 'postalCode':
                return !(POSTAL_CODE_REGEX.test(values[prop])) || !(values[prop].length === 5)
            case 'email':
                return !EMAIL_REGEX.test(values[prop])
            case 'checkBox':
                return !values[prop]
            // default:
            //     return undefined
        }
    }

    const handleFocusLeave = (prop: keyof State) => (event: React.FocusEvent<HTMLInputElement>) => {
        setErrors({...errors, [prop]: validateInput(prop)})
    }

    async function handleOrder() {
        const data = await orderProducts({order: shop, information: values, data: stepper.data})
      
    }

    const handleNext = () => {
        const validation = Object.keys(values).filter((value, index) => {
            return validateInput(value) === true
        })
        
        if (validation.length === 0) {
            API.post('/order',
                {
                    information: values,
                    order: shop,
                    data: stepper.wichtel,
                })
                .then(res => {
                    dispatch(setNext(res.data))
                })
                .catch(e => {
                },)

            //  dispatch(setNext())
        } else {
            setErrors({...allErrors, ...errors})
        }

    }


    return (
        <div className={'orderStep'}>
            <Grid container className="formRow" spacing={2}>
                <Grid item xs={12}>
                    <Typography variant={'body1'}>
                        Bitte gebe hier deine <strong>Bezahlinformationen</strong> ein. Die Zahlungsart ist
                        Rechnung.
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField fullWidth variant={'filled'} label={'Vorname'} required error={errors.firstname}
                               onBlur={handleFocusLeave('firstname')}
                               onChange={handleChange('firstname')}
                               value={values.firstname}
                               id={'firstname'} name={'firstname'}/>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField fullWidth variant={'filled'} label={'Nachname'} required error={errors.lastname}
                               onBlur={handleFocusLeave('lastname')}
                               onChange={handleChange('lastname')}
                               value={values.lastname}
                               id={'lastname'} name={'lastname'}/>
                </Grid>
                <Grid item xs={12}>
                    <TextField fullWidth variant={'filled'} label={'Straße'} required error={errors.streetName}
                               onChange={handleChange('streetName')} onBlur={handleFocusLeave('streetName')}
                               value={values.streetName} name={'streetName'} id={'streetName'}/>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField fullWidth variant={'filled'} label={'PLZ'} required
                               onChange={handleChange('postalCode')}
                               error={errors.postalCode} onBlur={handleFocusLeave('postalCode')}
                               value={values.postalCode} name={'postalCode'} id={'postalCode'}/>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField fullWidth variant={'filled'} label={'Stadt'} required onChange={handleChange('city')}
                               error={errors.city} onBlur={handleFocusLeave('city')}
                               value={values.city} name={'city'} id={'city'}/>
                </Grid>
                <Grid item xs={12}>
                    <TextField type={'email'} fullWidth variant={'filled'} label={'E-Mail'} required
                               error={errors.email} onBlur={handleFocusLeave('email')}
                               onChange={handleChange('email')} value={values.email} name={'email'} id={'email'}/>
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={<Checkbox required checked={values.checkBox} onClick={handleCheckbox}/>}
                        label="Ich habe die AGBs gelesen und bin mit der Datenschutzerklärung einverstanden"/>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant={'body2'} sx={{mb:1}}>Hier kannst Du deinen Rabatt-Code hinterlegen. Der Rabatt wird dann auf deiner endgültigen Rechnung abgezogen. Im Shop wird dieser <strong>nicht</strong> angezeigt.</Typography>
                    <TextField onChange={handleChange('notice')} fullWidth multiline variant={'filled'} rows={4}
                               value={values.notice}
                               name={'notice'}
                               label={'Anmerkungen'}/>
                </Grid>
            </Grid>
            <SteperActions handleNext={handleNext}/>
        </div>
    )
}