import React, {useState} from 'react'
import ShoppingListItem from './ShoppingListItem'
import AddProduct from './AddProduct'
import {useSelector} from 'react-redux'
import {TransitionGroup} from 'react-transition-group'
import {Box, Collapse, List, Slide, Typography} from '@mui/material'

export interface itemProps {
    id: number,
    name: string,
    count: number,
    price: number,
}


export default function ShoppingList() {

    const reduxStore: any = useSelector(state => state)
    const items: itemProps[] = reduxStore.shop

    let sum = 0
    items.forEach(item => {
        sum += item.price * item.count
    })
    if (items.length > 0) {
        sum += 3.5
    }


    return (
        <Box sx={{mt: 1}} className={'shoppingList'}>
            <List>
                <TransitionGroup>
                    {items.map(item =>
                        (<Collapse key={item.id}>
                            <ShoppingListItem {...item}/>
                        </Collapse>)
                    )}
                </TransitionGroup>
                <div className={'shopSum'}>
                    {
                        items.length > 0 ?
                            <Typography variant={'body2'} component={'div'} align="right" sx={{marginTop: 3}}>
                                zzgl. Versandkosten: 3,50€
                            </Typography> : ''
                    }
                    <Typography variant={'h5'} component={'div'} align={'right'} sx={{my: 3}}>
                        Gesamtsumme: {Math.round((sum) * 100) / 100} €
                    </Typography>
                </div>
            </List>
        </Box>
    )
}