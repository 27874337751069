import {Info} from '@mui/icons-material'
import {Box, Button, Container, Grid, Paper, Stack, Typography} from '@mui/material'
import {ReactNode} from 'react'

type ShopWrapperProps = {
	children:ReactNode|ReactNode[]
}

const ShopWrapper  =({children}:ShopWrapperProps)=>{
	
	return(
<>
	<Box sx={{
		backgroundColor:'secondary.main',py:12,
	}}>
		<Container>
			<Stack direction={'row'}>
				<Button variant={'contained'} href={'https://www.new-perspektiven.de'}>Zurück zu NEW Perspektiven</Button>
			</Stack>
			<Grid container>
				<Grid item xs={12} md={6}>
					<Typography variant={'h1'} sx={{color:'#fff',py:4, fontWeight:700}}>
						NEW Shoppen
					</Typography>
				</Grid>
				<Grid item xs={12} md={6}>
					<Box py={4}>
						<Typography sx={{color:'#fff'}}>
							Ihr seid stolz, NEW-lerin oder NEW-ler zu sein und wollt das auch zeigen? Oder seid zu Besuch im schönen Landkreis Neustadt an der Waldnaab und möchtet eine Erinnerung für Zuhause? Oder wollt ihr einfach einem lieben Menschen eine Freude machen und sucht ein besonderes Geschenk?
							Dann seid ihr im NEW Online-Shop genau richtig!
							<br/><br/>
							<strong>
								Wir wünschen euch viel Spaß beim Stöbern und NEW Shoppen!
							</strong>
							<br/><br/>
							PS: Alle unsere Produkte aus dem Online-Shop sind außerdem in unserem Tourismusbüro in Neustadt an der Waldnaab erhältlich.
						</Typography>
					</Box>
					
				</Grid>
			</Grid>
		</Container>
	</Box>
	<Box my={12}>
		<Container>
			<Typography variant={'h2'} sx={{fontWeight:700, color:'primary.main'}}>
				Denk mal NEW
			</Typography>
		</Container>
	</Box>
	{children}
	<Box component={'footer'} sx={{
		mt:12,py:4,
		backgroundColor:'primary.dark'
	}}>
		<Container>
			<Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
				<Typography variant={'body1'} sx={{color:'#fff'}}>Dein NEW Shop, 2023</Typography>
				<Stack alignItems={'center'}  direction={'row'} spacing={4}>
					<Typography component={'a'} href={'https://www.new-perspektiven.de/impressum'} variant={'body1'} target={'_blank'} sx={{color:'#fff'}}>Impressum</Typography>
					<Typography component={'a'} href={'https://www.new-perspektiven.de/datenschutz'} target={'_blank'} variant={'body1'} sx={{color:'#fff'}}>Datenschutzerklärung</Typography>
				</Stack>
			</Stack>
		</Container>
	</Box>
</>
	)
	
	
	
}
export default ShopWrapper